import React from 'react';
import TextField from '@mui/material/TextField';
import { ContactTextFieldProps } from './ContactTextFieldProps';

const ContactTextField: React.FC<ContactTextFieldProps> = ({ id, label, type, value, onChange }) => {
  return (
    <TextField
      id={id}
      label={label}
      type={type ? type : 'text'}
      variant="standard"
      required
      value={value}
      sx={{ width: '48%' }}
      InputProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.03)', height: '50px' } }}
      onChange={(e) => onChange?.(e)}
    />
  );
};

export default ContactTextField;
