import Grid from '@mui/material/Grid';
import CarouselUi from 'react-material-ui-carousel';
import CarouselItems from './CarouselItems/CarouselItems';

import { ImageItems } from './ImageItems';

const Carousel = () => {
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <CarouselUi
          animation="fade"
          duration={1500}
          interval={8000}
          stopAutoPlayOnHover={false}
          indicators={false}
          swipe
          navButtonsAlwaysInvisible
        >
          {ImageItems.map(({ name, img }, i) => (
            <CarouselItems key={i} name={name} img={img} />
          ))}
        </CarouselUi>
      </Grid>
    </Grid>
  );
};

export default Carousel;
