import { Icon } from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import location from '../../assets/location.svg';

const Map = () => {
  const myIcon = new Icon({
    iconUrl: location,
    iconSize: [50, 50],
  });

  return (
    <MapContainer
      style={{ height: '350px', width: '100%', filter: 'saturate(0.5)' }}
      center={[-19.93642, -43.94501]}
      zoom={17}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[-19.93642, -43.94501]} icon={myIcon}></Marker>
    </MapContainer>
  );
};

export default Map;
