import Meta from '../../components/Meta/Meta';
import Grid from '@mui/material/Grid';

// import User from '../../components/User/User';

// import { UsersItems } from '../../components/User/UsersItems';
import Typography from '@mui/material/Typography';

const AboutUs = () => {
  return (
    <>
      <Meta title="Sobre | Quintal Arquitetos" />
      <Grid display={'flex'} flexDirection="column" alignItems={'center'} paddingTop={15} paddingBottom={15}>
        {/* <Typography textAlign={'center'} variant={'h4'} paddingBottom={5}>
        SOBRE NÓS
      </Typography> */}
        <Grid container display={'flex'} flexDirection="row" justifyContent={'space-around'} xs={12} xl={10}>
          <Typography>Em Construção</Typography>
          {/* {UsersItems.map((user) => (
            <User {...user} />
          ))} */}
        </Grid>
      </Grid>
    </>
  );
};

export default AboutUs;
