import carousel1 from '../../assets/carousel1.jpg';
import carousel2 from '../../assets/carousel2.jpg';
import carousel3 from '../../assets/carousel3.jpg';
import carousel4 from '../../assets/carousel4.jpg';

import { CarouselItems } from '../../models/CarouselItems';

export const ImageItems: CarouselItems[] = [
  {
    name: 'Carousel #1',
    img: carousel1,
  },
  {
    name: 'Carousel #2',
    img: carousel2,
  },
  {
    name: 'Carousel #3',
    img: carousel3,
  },
  {
    name: 'Carousel #3',
    img: carousel4,
  },
];
