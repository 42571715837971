import Meta from '../../components/Meta/Meta';
// import ProjectsItems from '../../components/ProjectsItems/ProjectsItems';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import Typography from '@mui/material/Typography';

// import { Projects as ProjecsData } from '../../components/ProjectsItems/Projects';

const Projects = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Meta title="Projetos | Quintal Arquitetos" />
      <Grid display={'flex'} flexDirection="column" alignItems={'center'} paddingTop={15} paddingBottom={15}>
        <Typography>Em Construção</Typography>
        <ImageList sx={{ width: phone ? '80%' : '70%' }} cols={phone ? 1 : 3} gap={20}>
          {/* {ProjecsData.map((data) => (
            <ProjectsItems key={data.id} {...data} />
          ))} */}
        </ImageList>
      </Grid>
    </>
  );
};

export default Projects;
