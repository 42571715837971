import Meta from '../../components/Meta/Meta';
import Carousel from '../../components/Carousel/Carousel';
import 'leaflet/dist/leaflet.css';

const LandingPage = () => {
  return (
    <>
      <Meta title="Home | Quintal Arquitetos" />
      <Carousel />
    </>
  );
};

export default LandingPage;
