import Helmet from 'react-helmet';
import { MetaProps } from './MetaProps';

const Meta: React.FC<MetaProps> = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Quintal Arquitetos.',
  description:
    'Escritório de Arquitetura e Interiores especializado em projetos residenciais para condomínios fechados.',
  keywords:
    'arquiteto, arquitetos, arquitetura, construção, obra, obras, casa, casas, residencia, residencias, residenciais, apartamento, apartamentos, predio, predios, condomínio, condomínios, reforma, reformas',
};

export default Meta;
