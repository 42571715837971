import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';

import logo from '../../assets/logo.png';
import logoBackground from '../../assets/logo-background.jpeg';

const HeaderV2 = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = React.useState<number>();

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const pages = React.useMemo(
    () => [
      { id: 0, name: 'Home', route: '' },
      { id: 1, name: 'Projetos', route: 'projetos' },
      { id: 2, name: 'Sobre', route: 'sobre' },
      { id: 3, name: 'Contato', route: 'contato' },
    ],
    [],
  );

  React.useEffect(() => {
    for (let page of pages) {
      if (page.name.toLocaleLowerCase() === location.pathname.split('/')[1].toLocaleLowerCase()) setTab(page.id);
    }
  }, [location, pages]);

  const handleCloseNavMenu = (page: string) => {
    navigate(page);
  };

  const list = () => (
    <>
      <CloseIcon sx={{ padding: 3, position: 'absolute' }} onClick={() => setOpenDrawer(false)} />
      <Box height={'100vh'} role="presentation" onClick={() => setOpenDrawer(false)}>
        <List sx={{ paddingTop: '75px', paddingLeft: '20px' }}>
          {pages.map(({ name, route, id }) => (
            <Button
              fullWidth
              key={name}
              onClick={() => {
                handleCloseNavMenu(route);
                setTab(id);
              }}
              sx={{
                fontSize: '0.8rem',
                color: 'black',
                justifyContent: 'flex-start',
                paddingBottom: '15px',
              }}
            >
              {name}
            </Button>
          ))}
        </List>
      </Box>
    </>
  );

  return (
    <>
      <img
        src={logo}
        alt="logo"
        loading="lazy"
        style={{
          zIndex: location.pathname !== '/' ? -100 : 10,
          width: md ? '80%' : location.pathname !== '/' ? '0%' : '40%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: md && location.pathname !== '/' ? 0 : location.pathname !== '/' ? 0.02 : 'unset',
        }}
      />
      {location.pathname !== '/' ? (
        <img
          src={logoBackground}
          alt="logo"
          loading="lazy"
          style={{
            zIndex: location.pathname !== '/' ? -100 : 10,
            width: sm ? '400%' : md ? '200%' : lg ? '130%' : '100%',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: location.pathname !== '/' ? 0.04 : 'unset',
          }}
        />
      ) : null}
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: 'none',
          display: { xs: 'unset', md: 'flex' },
          alignItems: { xs: 'unset', md: 'center' },
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            height: '60px',
            position: 'fixed',
            zIndex: 100,
            width: { xs: '100%' },
            backgroundColor: location.pathname !== '/' ? 'white' : 'unset',
            borderBottom: location.pathname !== '/' ? '2px solid #f2f2f1' : 'unset',
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { lg: '80%', xs: 'unset' },
            }}
          >
            {/* {location.pathname !== '/' ? (
              <Grid
                item
                width={280}
                alignItems={'end'}
                container
                flexDirection={'column'}
                sx={{ display: { xs: 'none', md: 'flex', position: 'fixed' } }}
              >
                <img src={logo} width={'100%'} alt="logo" loading="lazy" />
              </Grid>
            ) : null} */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <MenuIcon onClick={() => setOpenDrawer(true)} />
                <Drawer
                  PaperProps={{ sx: { width: '40%' } }}
                  anchor={'left'}
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                >
                  {list()}
                </Drawer>
              </IconButton>
            </Box>
            <Grid
              item
              width={180}
              alignItems={'end'}
              container
              flexDirection={'column'}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              {location.pathname !== '/' ? <img src={logo} width={'100%'} alt="logo" loading="lazy" /> : null}
            </Grid>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'center' } }}>
              <Grid display={'flex'} justifyContent="space-between" width={'30%'}>
                {pages.map(({ name, route, id }) => (
                  <Button
                    key={name}
                    onClick={() => {
                      handleCloseNavMenu(route);
                      setTab(id);
                    }}
                    sx={{
                      my: 2,
                      fontSize: '0.8rem',
                      color: 'black',
                      display: 'block',
                      backgroundColor: tab === id && location.pathname !== '/' ? 'aliceblue' : 'unset',
                    }}
                  >
                    {name}
                  </Button>
                ))}
              </Grid>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default HeaderV2;
