import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderV2 from './components/Header/HeaderV2';

// import About from './pages/About/About';
import AboutUs from './pages/AboutUs/AboutUs';
// import Project from './pages/Project/Project';
import Projects from './pages/Projects/Projects';
import LandingPage from './pages/LandingPage/LandingPage';
import ContactUsPage from './pages/ContactUs/ContactUsPage';

import theme from './theme/theme';

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <HeaderV2 />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/sobre" element={<AboutUs />} />
          {/* <Route path="/sobre/:id" element={<About />} /> */}
          <Route path="/contato" element={<ContactUsPage />} />
          <Route path="/projetos" element={<Projects />} />
          {/* <Route path="/projeto/:id" element={<Project />} /> */}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
