import React from 'react';
import Meta from '../../components/Meta/Meta';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Map from '../../components/Map/Map';
import ContactTextField from '../../components/ContactTextField/ContactTextField';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

// import validator from 'validator';

const ContactUsPage = () => {
  const [name, setName] = React.useState('');
  const [sobreNome, setSobreNome] = React.useState('');
  const [cidade, setCidade] = React.useState('');
  const [pais, setPais] = React.useState('');
  // const [validEmail, setValidEmail] = React.useState(false);
  const [tel, setTel] = React.useState('');
  const [message, setMessage] = React.useState('');

  const verifyEmail = (email: string) => {
    // if (validator.isEmail(email)) {
    //   setValidEmail(true);
    // } else {
    //   setValidEmail(false);
    // }
  };

  // const invalidMessage = !name || !sobreNome || !cidade || !pais || !validEmail || !tel || !message;

  return (
    <>
      <Meta title="Contato | Quintal Arquitetos" />
      <Grid item container paddingTop={15} flexDirection="column" alignItems={'center'} xs={12}>
        <Grid
          item
          container
          display={'flex'}
          justifyContent={'center'}
          xs={11}
          md={9}
          lg={8}
          xl={7}
          sx={{ flexDirection: { xs: 'column', sm: 'unset' } }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: { xs: 'flex', sm: 'unset' }, justifyContent: { xs: 'space-between', sm: 'unset' } }}
          >
            <Grid>
              <Typography fontWeight={'bold'} fontSize="1.1rem">
                Endereço
              </Typography>
              <Typography paddingTop={1} fontSize={'0.9rem'}>
                Av. do Contorno, 7218
              </Typography>
              <Typography paddingTop={1} fontSize={'0.9rem'}>
                Sala 703
              </Typography>
              <Typography paddingTop={1} fontSize={'0.9rem'}>
                Bairro: Lourdes
              </Typography>
              <Typography paddingTop={1} fontSize={'0.9rem'}>
                Belo Horizonte - MG
              </Typography>
              <Typography paddingTop={1} fontSize={'0.9rem'}>
                CEP: 30110-084
              </Typography>
              <Typography paddingTop={1} paddingBottom={3} fontSize={'0.9rem'}>
                Telefone: (31) 8643-1283
              </Typography>
            </Grid>
            <Grid>
              <Grid
                item
                component={'a'}
                href="https://www.instagram.com/quintalarquitetos/"
                rel="noopener noreferrer"
                target="_blank"
                paddingBottom={1}
                sx={{ textDecoration: 'none' }}
                display={'flex'}
              >
                <InstagramIcon sx={{ color: 'black' }} fontSize={'small'} />
                <Typography paddingLeft={1} color={'black'} fontSize="0.9rem">
                  Instagram
                </Typography>
              </Grid>
              <Grid
                item
                component={'a'}
                href="https://www.facebook.com/quintalarquitetos/"
                rel="noopener noreferrer"
                target="_blank"
                paddingBottom={1}
                sx={{ textDecoration: 'none' }}
                display={'flex'}
              >
                <FacebookIcon sx={{ color: 'black' }} fontSize={'small'} />
                <Typography paddingLeft={1} color={'black'} fontSize="0.9rem">
                  Facebook
                </Typography>
              </Grid>
              <Grid
                item
                component={'a'}
                href="mailto:quintalarquitetos@gmail.com"
                rel="noopener noreferrer"
                target="_blank"
                paddingBottom={1}
                sx={{ textDecoration: 'none' }}
                display={'flex'}
              >
                <EmailIcon sx={{ color: 'black' }} fontSize={'small'} />
                <Typography paddingLeft={1} color={'black'} fontSize="0.9rem">
                  Email
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              component={'form'}
              autoComplete="off"
              noValidate
              display={'flex'}
              flexDirection="column"
              paddingBottom={5}
            >
              <Typography fontWeight={'bold'} fontSize="1.1rem">
                Contato
              </Typography>
              <Grid display={'flex'} flexDirection="row" justifyContent={'space-between'}>
                <ContactTextField id={'name'} label={'Name'} value={name} onChange={(e) => setName(e.target.value)} />
                <ContactTextField
                  id="sobrenome"
                  label="Sobrenome"
                  value={sobreNome}
                  onChange={(e) => setSobreNome(e.target.value)}
                />
              </Grid>
              <Grid display={'flex'} flexDirection="row" justifyContent={'space-between'}>
                <ContactTextField
                  id="cidade"
                  label="Cidade"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                />
                <ContactTextField id="país" label="País" value={pais} onChange={(e) => setPais(e.target.value)} />
              </Grid>
              <Grid display={'flex'} flexDirection="row" justifyContent={'space-between'}>
                <ContactTextField id="email" label="Email" type="email" onChange={(e) => verifyEmail(e.target.value)} />
                <ContactTextField
                  id="telefone"
                  label="Telefone"
                  type="tel"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                />
              </Grid>
              <TextField
                id="mensagem"
                label="Mensagem"
                type="text"
                variant="standard"
                multiline
                required
                rows={4}
                InputProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.03)' } }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{
                  alignSelf: 'center',
                  marginTop: '15px',
                  height: '40px',
                  width: '140px',
                  WebkitAlignSelf: 'end',
                }}
                disabled
              >
                Enviar
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item container display={'flex'} justifyContent={'center'} xs={12} md={9} lg={8} xl={7}>
          <Map />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUsPage;
